import React, { useEffect, useState, forwardRef, useRef } from 'react';
import parse from 'html-react-parser';
import striptags from 'striptags';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'swiper/swiper-bundle.min.css';
import Header from '../components/Header';
import Footer from '../components/Footer';
import MFooter from '../components/MFooter';
import PcHomeProgram from '../components/pcHomeProgram';
import MobileHomeProgram from '../components/mobileHomeProgram';
import MobileMainHomeProgram from '../components/mobileMainHomeProgram';
import NoticeItem from '../components/NoticeItem';
import images from '../libs/images';
import { requestPost } from '../libs/request';
import api from '../libs/api';
import Config from '../Config';
import { formatDate, formatDate1, formatDate2 } from '../libs/utils';
import { getCookie, itemKey } from '../libs/cookie';
import Menu from '../components/Menu';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import ko from 'date-fns/locale/ko';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, EffectFade } from 'swiper';
import PreLoadImage from '../components/PreLoadImage';
registerLocale('ko', ko);


const settings = {
  autoplay: true,
  autoplaySpeed: 3000,
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  spaceBetween: 20,
  arrows: false,
  cssEase: 'linear',
  asNavFor: null,
  responsive: [
    {
      breakpoint: 1140,
      settings: {
        slidesToShow: 2
      }
    },
    {
      breakpoint: 850,
      settings: {
        slidesToShow: 1
      }
    },

  ]
};
const settingsSecondProgram = {
  autoplay: true,
  autoplaySpeed: 5000,
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 2,
  slidesToScroll: 1,
  spaceBetween: 15,
  arrows: false,
  cssEase: 'linear',
  asNavFor: null,
  responsive: [
    {
      breakpoint: 1140,
      settings: {
        slidesToShow: 1
      }
    },
    {
      breakpoint: 850,
      settings: {
        slidesToShow: 1
      }
    },

  ]
};


const settings1 = {
  dots: false,
  autoplay: true,
  modules: [Autoplay],
  autoplaySpeed: 3000,
  infinite: false,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 1,
  arrows: false,
  cssEase: 'linear',
  asNavFor: null,
  responsive: [
    {
      breakpoint: 1140,
      settings: {
        slidesToShow: 2
      }
    },
    {
      breakpoint: 850,
      settings: {
        slidesToShow: 2
      }
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2
      }
    },


  ]

};

const settings2 = {
  autoplay: true,
  autoplaySpeed: 3000,
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
  cssEase: 'linear',
  asNavFor: null
};

const Home = () => {
  const sliderRef0 = useRef();
  const sliderRef1 = useRef();
  const sliderRef2 = useRef();
  const sliderRef3 = useRef();
  const sliderRefUpcoming = useRef();
  const sliderRefAlways = useRef();
  const datePickerRef = useRef();
  const sliderReviewRef = useRef(null);

  const [swiper0, setSwiper0] = useState(null);
  const [swiper1, setSwiper1] = useState(null);
  const [swiper2, setSwiper2] = useState(null);


  const [swiperMobileUpcoming, setSwiperMobileUpcoming] = useState(null);
  const [swiperMobileAlways, setSwiperMobileAlways] = useState(null);


  const [mobile, setMobile] = useState(false);
  const [tablet, setTablet] = useState(false);
  const [login, setLogin] = useState(0);
  const [membership, setMembership] = useState(0);
  const [alimCnt, setAlimCnt] = useState(0);
  const [homeInfo, setHomeInfo] = useState(null);
  const [listBefore, setListBefore] = useState([]);
  const [listAwlays, setListAlways] = useState([]);
  const [list1, setList1] = useState([]);
  const [list2, setList2] = useState([]);
  const [list3, setList3] = useState([]);
  const [menu, setMenu] = useState(false);
  const [scrollPos, setScrollPos] = useState(0);
  const [winHeight, setWinHeight] = useState(0);
  const [adHeight, setAdHeight] = useState(0);
  const [popupList, setPopupList] = useState([]);
  const [dayList, setDayList] = useState([]);
  const [calendarList, setCalendarList] = useState([]);
  const [scheduleList, setScheduleList] = useState([]);
  const [selMonth, setSelMonth] = useState(new Date());
  const [calendarPopup, setCalendarPopup] = useState(false);
  const [calendarPopupList, setCalendarPopupList] = useState([]);
  const [selPos, setSelPos] = useState({ x: 0, y: 0 });
  const [noticeList, setNoticeList] = useState([]);

  /* eslint-disable */
  useEffect(() => {
    const c_login = getCookie(itemKey.login);
    setLogin(c_login ? parseInt(c_login) : 0);

    if (getCookie(itemKey.user_id)) {
      requestPost(api.getUserInfoById, { id: getCookie(itemKey.user_id) }, (result) => {
        if (result) {
          setMembership(result.membership);
          setAlimCnt(result.alim_cnt);
        }
        else {
          setMembership(0);
          setAlimCnt(0);
        }
      });
    }
    else {
      setMembership(0);
      setAlimCnt(0);
    }

    initCalendar(new Date().getFullYear(), new Date().getMonth());
    getCalendarList(new Date().getFullYear() + '-' + ('' + (new Date().getMonth() + 1)).padStart(2, '0'));
    getCalendarAddList(new Date().getFullYear() + '-' + ('' + (new Date().getMonth() + 1)).padStart(2, '0'));
    getScheduleList(new Date().getFullYear() + '-' + ('' + (new Date().getMonth() + 1)).padStart(2, '0'));

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.addEventListener('mousedown', handleClickOutside);
    }
  }, []);

  /* eslint-disable */
  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;

      if (width <= 768) {
        setMobile(true);
        setTablet(false);
        setWinHeight(window.innerHeight * 0.4 + 56);
      } else if (width <= 1024) {
        setMobile(false);
        setTablet(true);
        setWinHeight(window.innerHeight * 0.4 + 56);
      } else {
        setMobile(false);
        setTablet(false);
        setWinHeight(window.innerHeight * 0.4);
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // 초기 실행




    setTimeout(() => {
      const containerEl = document.querySelector(".container1");
      if (containerEl) {

        containerEl.addEventListener('scroll', handleScroll, { passive: true });
      }
    }, 500)


    return () => {
      window.removeEventListener('resize', handleResize);
      const containerEl = document.querySelector(".container1");
      if (containerEl) {
        containerEl.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  useEffect(() => {
    let storage_date = localStorage.getItem('essene_date');
    let today = formatDate(new Date()).replace(/-/g, '');

    requestPost(api.getNoticePopupList, {}, (result) => {
      if (result) {
        if (storage_date) {
          if (parseInt(storage_date) < parseInt(today))
            setPopupList(result);
          else
            setPopupList([]);
        }
        else
          setPopupList(result);
      }
      else
        setPopupList([]);
    });

    requestPost(api.getProgramList, { preview: 0, category_id: 0, sub_category_id: 0, s_limit: 0, limit_cnt: 6 }, (result) => {
      if (result)
        setList1(result);
      else
        setList1([]);
    });

    requestPost(api.getProgramAlwaysList, { preview: 0, category_id: 0, sub_category_id: 0, s_limit: 0, limit_cnt: 6 }, (result) => {
      if (result)
        setListAlways(result);
      else
        setListAlways([]);
    });

    requestPost(api.getProgramBeforeList, { preview: 0, category_id: 0, sub_category_id: 0, s_limit: 0, limit_cnt: 6 }, (result) => {
      if (result)
        setListBefore(result);
      else
        setListBefore([]);
    });

    requestPost(api.getWebzineList, { preview: 0, category: '', s_limit: 0, limit_cnt: 6 }, (result) => {
      if (result)
        setList2(result);
      else
        setList2([]);
    });

    requestPost(api.getHomeReviewList, {}, (result) => {
      if (result)
        setList3(result);
      else
        setList3([]);
    });

    requestPost(api.getHomeInfo, {}, (result) => {
      if (result) {
        setHomeInfo(result);


        if (window.innerWidth <= 850) {
          if (result.mobile_img !== '')
            setAdHeight(60);
          else
            setAdHeight(0);
        }
        else {
          if (result.pc_img !== '')
            setAdHeight(60);
          else
            setAdHeight(0);
        }
      }
      else
        setHomeInfo(null);
    });


    requestPost(api.getNoticeList, { s_limit: 0, limit_cnt: 3 }, (result) => {
      if (result) {
        setNoticeList(result);
      }
      else {
        setNoticeList([]);
      }
    });
  }, []);

  const handleScroll = () => {
    const containerEl = document.querySelector(".container1")
    if (containerEl) {
      setScrollPos(containerEl.scrollTop);
    }

  };

  const getCalendarList = (year_month) => {
    requestPost(api.getPrimeCalendarList, { year_month: year_month }, (result) => {
      if (result) {
        setCalendarList(result);
      }
      else
        setCalendarList([]);
    });
  }
  const getCalendarAddList = (year_month) => {
    requestPost(api.getPrimeCalendarAddList, { year_month: year_month }, (result) => {
      if (result) {
        setCalendarList(prevList => [...prevList, ...result]);
      } else {
        setCalendarList(prevList => [...prevList]);
      }
    });
  }

  const getScheduleList = (year_month) => {
    requestPost(api.getScheduleList, { year_month: year_month }, (result) => {
      if (result && result.error !== "No data found") {
        setScheduleList(result);
      } else {
        setScheduleList([]);
      }
    });
  };



  const initCalendar = (year, month) => {
    let arr = [];
    let toYear = year;
    let toMonth = month;
    let firstDay = new Date(toYear, toMonth, 1).getDay();
    let lastDate = new Date(toYear, toMonth + 1, 0).getDate();
    let lastDay = new Date(toYear, toMonth + 1, 0).getDay();

    for (let i = 0; i < firstDay; i++)
      arr.push(0);

    for (let i = 1; i <= lastDate; i++)
      arr.push(i);

    for (let i = 0; i < 6 - lastDay; i++)
      arr.push(0);

    setDayList(arr);
  };
  const getDatesBetween = (startDate, endDate) => {
    const start = new Date(startDate);
    const end = new Date(endDate);
    const dates = [];

    if (start.getTime() === end.getTime()) {
      dates.push({
        date: new Date(start),
        date_type: 3
      });
    } else {
      while (start <= end) {
        const isFirstDate = dates.length === 0; // 첫 번째 날짜인지 확인
        const isLastDate = start.getTime() === end.getTime(); // 마지막 날짜인지 확인

        dates.push({
          date: new Date(start),
          date_type: isFirstDate ? 0 : isLastDate ? 2 : 1 // 첫 번째 날짜는 0, 마지막 날짜는 2, 중간 날짜는 1
        });

        start.setDate(start.getDate() + 1);
      }

    }

    return dates;
  };

  const CustomInput = forwardRef(({ value, onClick }, ref) => (
    <button className='pc-ml100 datepicker pc-font32 color11 fb' onClick={onClick} ref={ref}>{value}</button>
  ));
  const getDataArrMo = (day) => {
    const formattedDate = selMonth.getFullYear() + '-' + ('' + (selMonth.getMonth() + 1)).padStart(2, '0') + '-' + ('' + day).padStart(2, '0');

    const calendarEvents = calendarList.filter((obj) => formatDate(obj.date) === formattedDate);


    
    return [...calendarEvents];
  };

  const getDataArr = (day) => {
    const formattedDate = selMonth.getFullYear() + '-' + ('' + (selMonth.getMonth() + 1)).padStart(2, '0') + '-' + ('' + day).padStart(2, '0');

    const calendarEvents = calendarList.filter((obj) => formatDate(obj.date) === formattedDate);

    const scheduleEvents = scheduleList.flatMap((schedule) => {
      const dates = getDatesBetween(schedule.start_date, schedule.end_date);
      return dates
        .filter(dateObj => formatDate(dateObj.date) === formattedDate)
        .map(dateObj => ({ ...schedule, date_type: dateObj.date_type }));
    });


    return [...calendarEvents, ...scheduleEvents];
  };

  const isToday = (day) => {
    return formatDate(new Date()) === selMonth.getFullYear() + '-' + ('' + (selMonth.getMonth() + 1)).padStart(2, '0') + '-' + ('' + day).padStart(2, '0');
  };

  const prevMonth = () => {
    let t_month = new Date(selMonth);
    t_month.setMonth(t_month.getMonth() - 1);
    setSelMonth(t_month);
    initCalendar(t_month.getFullYear(), t_month.getMonth());
    getCalendarList(t_month.getFullYear() + '-' + ('' + (t_month.getMonth() + 1)).padStart(2, '0'));
    getScheduleList(t_month.getFullYear() + '-' + ('' + (t_month.getMonth() + 1)).padStart(2, '0'));
    getCalendarAddList(t_month.getFullYear() + '-' + ('' + (t_month.getMonth() + 1)).padStart(2, '0'));
  };

  const nextMonth = () => {
    let t_month = new Date(selMonth);
    t_month.setMonth(t_month.getMonth() + 1);
    setSelMonth(t_month);
    initCalendar(t_month.getFullYear(), t_month.getMonth());
    getCalendarList(t_month.getFullYear() + '-' + ('' + (t_month.getMonth() + 1)).padStart(2, '0'));
    getCalendarAddList(t_month.getFullYear() + '-' + ('' + (t_month.getMonth() + 1)).padStart(2, '0'));
    getScheduleList(t_month.getFullYear() + '-' + ('' + (t_month.getMonth() + 1)).padStart(2, '0'));
  };

  const handleClickOutside = (e) => {
    if (!e.target.classList.contains('calendar-popup-btn') && !e.target.classList.contains('popup-link'))
      setCalendarPopup(false);
  };
  const sliderSettings = {
    spaceBetween: 15,
    slidesPerView: 2,
    autoplay: {
      delay: 3000,
      disableOnInteraction: false,
    },
    pagination: { clickable: true },
    onSwiper: (swiper) => {
      sliderRef3.current = swiper;
    },
    breakpoints: {
      850: {
        slidesPerView: 2,
      },
    },
    modules: [Autoplay]

  }
  const HomeBannerSettings = {
    autoplay: true,
    autoplaySpeed: 3000,
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  };
  const banners = [
    {
      img: mobile
        ? homeInfo?.home_banner_mo1
        : tablet
          ? homeInfo?.home_banner_tb1
          : homeInfo?.home_banner1,
      link: homeInfo?.home_banner_link1
    },
    {
      img: mobile
        ? homeInfo?.home_banner_mo2
        : tablet
          ? homeInfo?.home_banner_tb2
          : homeInfo?.home_banner2,
      link: homeInfo?.home_banner_link2
    },
    {
      img: mobile
        ? homeInfo?.home_banner_mo3
        : tablet
          ? homeInfo?.home_banner_tb3
          : homeInfo?.home_banner3,
      link: homeInfo?.home_banner_link3
    }
  ].filter(banner => banner.img && banner.img !== '');


  return (
    <>
      {homeInfo && (
        <>
          <div className='pc'>
            <Header login={login} membership={membership} alimCnt={alimCnt} pos={scrollPos} winHeight={winHeight} />
          </div>
          <div className='mobile'>
            <div className='m-header1'>
              <a href='/'>
                <img src={images.logo3} className='w164' alt='' />
              </a>
            </div>
          </div>
          <div className="container1 bgf">
            <div className='bg-img'>
              {banners.length > 0 && (
                <Slider {...HomeBannerSettings} ref={sliderRef0}>
                  {banners.map((banner, index) => (
                    <div key={index}>
                      <a href={banner.link || '#'}>
                        <img src={Config.IMG_URL + banner.img} alt='' className='w-100' />
                      </a>
                    </div>
                  ))}
                </Slider>
              )}
              <button
                className='h32'
                onClick={() => sliderRef0 && sliderRef0.current.slickPrev()}
              >
                <div className='left-arrow'>
                  <img src={images.img5} className='arrow' alt='' />
                </div>
              </button>
              <button
                className='h32'
                onClick={() => sliderRef0 && sliderRef0.current.slickNext()}>
                <div className='right-arrow'>
                  <img src={images.img6} className='arrow' alt='' />
                </div>
              </button>
            </div>
            {scrollPos > winHeight - 80 && (
              <>
                {popupList.length > 0 && (
                  <>
                    <div className='overlay4'></div>
                    <div className='notice-popup'>
                      <Slider {...settings2}>
                        {popupList.map((data, index) => (
                          <div key={index} className="slidePar">
                            <button className='img flex-center' style={{ backgroundImage: 'url("' + (Config.IMG_URL + data.img) + '")' }} onClick={() => {
                              window.location = '/noticeDetail?id=' + data.id;
                            }}></button>
                          </div>
                        ))}
                      </Slider>
                      <div className='flex-between'>
                        <button className='flex1 ptb10 font12' onClick={() => {
                          localStorage.setItem('essene_date', formatDate(new Date()).replace(/-/g, ''));
                          setPopupList([]);
                        }}>오늘 하루 보지 않기</button>
                        <div className='v-line2'></div>
                        <button className='flex1 ptb10 font12' onClick={() => setPopupList([])}>닫기</button>
                      </div>
                    </div>
                  </>
                )}
              </>
            )}
            <div className='pc-ptb100 flex-center relative totalProgram' style={{ backgroundColor: homeInfo.bg_color1 }}>
              <div className='pc'>
                <div className={'left-menu fixed'}>
                  <button className='hover-zoom pcMenuCol pcMenuBtn' onClick={() => setMenu(true)}>
                    <img src={images.img3} className='w36' alt='' />
                    <span>메뉴열기</span>
                  </button>
                  <a href='/schedule' className='hover-zoom pcMenuCol'>
                    <img src={images.img15} className='w36' alt='' />
                    <span>스케줄</span>
                  </a>
                </div>
              </div>
              <div className='m-w-100'>
                <div className='home-inner1'>
                  <div className={(scrollPos > (mobile ? -30 : -30) ? ' fade-up-ani' : '')}>
                    <a href='/program' className='pc-font48 color11 fb'>Program</a>
                  </div>
                  <div className='pc-mt10 flex-between morePar'>
                    <div className={(scrollPos > (mobile ? -30 : -30) ? ' fade-up-ani ani-delay-100' : '')}>{parse(homeInfo.sub_txt1)}</div>

                  </div>
                  {mobile || tablet ? <div className="mobile"><div className="flex-end"><a href="/program" className="">더보기</a></div></div> : ''}
                  <div className={'borderbd pc-mt40' + (scrollPos > (mobile ? -30 : -30) ? ' fade-up-ani ani-delay-100' : '')}></div>
                </div>
                <div className='slider-part slider-part0 pc1-mt40 programParBox proccesing'>
                  {list1.length > 0 &&
                    (
                      <>
                        <div className='pc'>
                          
                          <div className="secondProTit pcProccessingProgram" >
                            <div>진행중인 프로그램</div>
                            {((mobile && list1.length > 1) || list1.length > 3) && (
                      <div className='pc flex-row-center'>
                        <a href="/program" className="">더보기</a>
                        <button
                          className='h32'
                          onClick={() => sliderRef1 && sliderRef1.current.slickPrev()}
                        >
                          <div className='left-arrow'>
                            <img src={images.img5} className='arrow' alt='' />
                          </div>
                        </button>
                        <button
                          className='h32'
                          onClick={() => sliderRef1 && sliderRef1.current.slickNext()}>
                          <div className='right-arrow'>
                            <img src={images.img6} className='arrow' alt='' />
                          </div>
                        </button>
                      </div>
                    )}
                            </div>
                          <PcHomeProgram
                            sliderRef1={sliderRef1}
                            list={list1}
                            settings={settings}
                            scrollPos={scrollPos}
                            mobile={mobile}
                            setList={setList1}
                          />
                        </div>
                        <div className='pc secondProPar'>
                          <div className="upcommingProgram secondProgram">
                            {((mobile && listBefore.length > 1) || listBefore.length > 2) && (
                              <div className='pc flex-row-center secondProgramBtn'>
                                <button
                                  className='h32'
                                  onClick={() => sliderRefUpcoming && sliderRefUpcoming.current.slickPrev()}
                                >
                                  <div className='left-arrow'>
                                    <img src={images.img5} className='arrow' alt='' />
                                  </div>
                                </button>
                                <button
                                  className='h32'
                                  onClick={() => sliderRefUpcoming && sliderRefUpcoming.current.slickNext()}>
                                  <div className='right-arrow'>
                                    <img src={images.img6} className='arrow' alt='' />
                                  </div>
                                </button>
                              </div>
                            )}
                            <div className="secondProTit">예정 프로그램</div>
                            <PcHomeProgram
                              sliderRef1={sliderRefUpcoming}
                              list={listBefore}
                              //추후 예정 프로그램 리스트로 변경
                              settings={settingsSecondProgram}
                              scrollPos={scrollPos}
                              mobile={mobile}
                              setList={setListBefore}
                            //추후 예정 프로그램 리스트로 변경
                            />
                          </div>
                          <div className="alwaysProgram secondProgram">
                            {((mobile && listAwlays.length > 1) || listAwlays.length > 2) && (
                              <div className='pc flex-row-center secondProgramBtn'>
                                <button
                                  className='h32'
                                  onClick={() => sliderRefAlways && sliderRefAlways.current.slickPrev()}
                                >
                                  <div className='left-arrow'>
                                    <img src={images.img5} className='arrow' alt='' />
                                  </div>
                                </button>
                                <button
                                  className='h32'
                                  onClick={() => sliderRefAlways && sliderRefAlways.current.slickNext()}>
                                  <div className='right-arrow'>
                                    <img src={images.img6} className='arrow' alt='' />
                                  </div>
                                </button>
                              </div>
                            )}
                            <div className="secondProTit">상설 프로그램</div>
                            <PcHomeProgram
                              sliderRef1={sliderRefAlways}
                              list={listAwlays}
                              //추후 상설 프로그램 리스트로 변경
                              settings={settingsSecondProgram}
                              scrollPos={scrollPos}
                              mobile={mobile}
                              setList={setListAlways}
                            //추후 상설 프로그램 리스트로 변경
                            />
                          </div>
                        </div>
                        <MobileMainHomeProgram
                          list={list1}
                          setSwiperRef={setSwiper1}
                          swiperNext={() => swiper1 && swiper1.slideNext()}
                          swiperPrev={() => swiper1 && swiper1.slidePrev()}
                          scrollPos={scrollPos}
                          mobile={mobile}
                          Config={Config}
                          images={images}
                          title="진행중인 프로그램"
                        />
                        {(listBefore.length >= 1) && (
                        <MobileHomeProgram
                          list={listBefore}
                          title="예정 프로그램"
                          setSwiperRef={setSwiperMobileUpcoming}
                          swiperNext={() => swiperMobileUpcoming && swiperMobileUpcoming.slideNext()}
                          swiperPrev={() => swiperMobileUpcoming && swiperMobileUpcoming.slidePrev()}
                          scrollPos={scrollPos}
                          mobile={mobile}
                          Config={Config}
                          images={images}
                          main={false}
                        />
                      )}
                      {(listAwlays.length >= 1) && (
                        <MobileHomeProgram
                          list={listAwlays}
                          title="상설 프로그램"
                          setSwiperRef={setSwiperMobileAlways}
                          swiperNext={() => swiperMobileAlways && swiperMobileAlways.slideNext()}
                          swiperPrev={() => swiperMobileAlways && swiperMobileAlways.slidePrev()}
                          scrollPos={scrollPos}
                          mobile={mobile}
                          Config={Config}
                          images={images}
                          main={false}
                        />
                        )}
                      </>
                    )}
                </div>
              </div>
            </div>
            <div className='pc-ptb100 flex-center webzineArea' style={{ backgroundColor: homeInfo.bg_color2 }}>
              <div className='m-w-100'>
                <div className='home-inner1'>
                  <div className={(scrollPos > (mobile ? 395 : 920) ? ' fade-up-ani' : '')}>
                    <a href='/webzine' className='pc-font48 color11 fb'>Webzine</a>
                  </div>
                  <div className='pc-mt10 flex-between morePar'>
                    <div className={(scrollPos > (mobile ? 395 : 920) ? ' fade-up-ani ani-delay-100' : '')}>{parse(homeInfo.sub_txt2)}</div>
                    {((mobile && list2.length > 1) || list2.length > 3) && (
                      <div className='pc flex-row-center'>
                        <a href="/webzine" className="">더보기</a>
                        <button
                          className='h32'
                          onClick={() => sliderRef2 && sliderRef2.current.slickPrev()}
                        >
                          <div className='left-arrow'>
                            <img src={images.img5} className='arrow' alt='' />
                          </div>
                        </button>
                        <button
                          className='h32'
                          onClick={() => sliderRef2 && sliderRef2.current.slickNext()}>
                          <div className='right-arrow'>
                            <img src={images.img6} className='arrow' alt='' />
                          </div>
                        </button>
                      </div>
                    )}
                  </div>
                  {mobile || tablet ? <div className="mobile"><div className="flex-end"><a href="/webzine" className="">더보기</a></div></div> : ''}
                  <div className={'borderbd pc-mt40' + (scrollPos > (mobile ? 395 : 920) ? ' fade-up-ani ani-delay-100' : '')}></div>
                </div>
                <div className='slider-part  slider-part0 pc1-mt40 programParBox'>
                  {list2.length > 0 && (
                    <>
                      <div className='pc'>
                        <Slider ref={sliderRef2} {...settings1} className="slickWrap">
                          {list2.map((data, index) => (
                            <div key={index} className={scrollPos > (mobile ? 395 : 920) ? 'slidePar fade-up-ani ani-delay-' + (100 * (index + 2)) : 'slidePar'}>
                              <button
                                className='pc-w400 text-left'
                                onMouseOver={() => {
                                  list2[index].hover = 1;
                                  setList2([...list2]);
                                }}
                                onMouseOut={() => {
                                  list2[index].hover = 2;
                                  setList2([...list2]);
                                }}
                                onClick={() => window.location = '/webzineDetail?id=' + data.id}
                              >
                                <div className='webzine-img'>
                                  <PreLoadImage className={'img' + (data.hover === 1 ? ' zoom1' : (data.hover === 0 ? '' : ' zoom0'))} url={Config.IMG_URL + data.img} />
                                  {data.special === 1 ? <img src={images.img118} alt='' className="special" /> : ''}
                                </div>
                                <div className={'mt10 homeLast'}>
                                  <span>{data.category}</span>
                                  <span className='LastDivider'>|</span>
                                  <span>{formatDate1(data.publish_time)}</span>
                                  <span className='LastDivider'>|</span>
                                  <span>{data.editor}</span>
                                </div>
                                <p className={'homeTitle homeTitle2' + (data.hover === 1 ? ' underline' : '')}>{data.title}</p>
                                <div className={'mt4 homeShort homeShort2'}>{parse(striptags(data.content)).trim()}</div>
                              </button>
                            </div>
                          ))}
                        </Slider>
                      </div>
                      <div className='mobile m-flex-row-center programSlideBox'>
                        <button className='h32' onClick={() => swiper2 && swiper2.slidePrev()}>
                          <img src={images.img110} className='w20 invertBtn' alt='' />
                        </button>
                        <Swiper
                          onSwiper={(ref) => setSwiper2(ref)}
                          autoHeight={true}
                          spaceBetween={15}
                          navigation={false}
                          direction='horizontal' // 'horizontal','vertical'
                          effect='slide' // 'slide','fade','cube','coverflow','flip','creative','cards'
                          autoplay={{
                            delay: 3000,
                            disableOnInteraction: false,
                          }}
                          breakpoints={{
                            0: {
                              slidesPerView: 2,  // 768px 이하일 때 슬라이드 1개
                            },
                            768: {
                              slidesPerView: 2,  // 768px 이상일 때 슬라이드 2개
                            },
                          }}
                          modules={[Autoplay]}
                        >
                          {list2.map((data, index) => (
                            <SwiperSlide key={index} className={scrollPos > (mobile ? 395 : 920) ? ' fade-up-ani ani-delay-' + (100 * (index + 2)) : ''}>
                              <button
                                className='pc-w400 text-left'
                                onMouseOver={() => {
                                  list2[index].hover = 1;
                                  setList2([...list2]);
                                }}
                                onMouseOut={() => {
                                  list2[index].hover = 2;
                                  setList2([...list2]);
                                }}
                                onClick={() => window.location = '/webzineDetail?id=' + data.id}
                              >
                                <div className='webzine-img'>
                                  <PreLoadImage className={'img' + (data.hover === 1 ? ' zoom1' : (data.hover === 0 ? '' : ' zoom0'))} url={Config.IMG_URL + data.img} />
                                  {data.special === 1 ? <img src={images.img118} alt='' className="special" /> : ''}
                                </div>
                                <div className={'mt4 homeLast'}>
                                  <span>{data.category}</span>
                                  <span className='LastDivider'></span>
                                  <span>{formatDate1(data.reg_date)}</span>
                                  <span className='LastDivider'></span>
                                  <span>{data.editor}</span>
                                </div>
                                <p className={'mt4 homeTitle' + (data.hover === 1 ? ' underline' : '')}>{data.title}</p>
                                <div className={'mt4 homeShort homeShort2'}>{parse(striptags(data.content)).trim()}</div>
                              </button>
                            </SwiperSlide>
                          ))}
                        </Swiper>
                        <button className='h32' onClick={() => swiper2 && swiper2.slideNext()}>
                          <img src={images.img111} className='w20 invertBtn' alt='' />
                        </button>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
            <Swiper
              id="homeLineBanner"
              spaceBetween={0}
              slidesPerView={1}
              autoplay={{ delay: 3000, disableOnInteraction: false }}  // 3초마다 자동 재생, 상호작용 시 멈추지 않음
              effect="fade"  // 페이드 효과 설정
              modules={[Autoplay, EffectFade]}  // Swiper에 필요한 모듈 추가
              style={{ top: mobile ? '48px' : '0' }}  // Swiper 전체 스타일 적용
            >
              {homeInfo && (
                <>
                  {/* 첫 번째 이미지와 조건 */}
                  {(homeInfo.img_url || homeInfo.mobile_img || homeInfo.pc_img) && (
                    <SwiperSlide>
                      <button
                        className='ad'
                        style={{ backgroundImage: 'url("' + (Config.IMG_URL + (mobile ? homeInfo.mobile_img : homeInfo.pc_img)) + '")' }}
                        onClick={() => {
                          if (homeInfo.banner_url !== '')
                            window.location = homeInfo.banner_url;
                        }}
                      >
                      </button>
                    </SwiperSlide>
                  )}

                  {/* 두 번째 이미지와 조건 */}
                  {(homeInfo.img_url2 || homeInfo.mobile_img2 || homeInfo.pc_img2) && (
                    <SwiperSlide>
                      <button
                        className='ad'
                        style={{ backgroundImage: 'url("' + (Config.IMG_URL + (mobile ? homeInfo.mobile_img2 : homeInfo.pc_img2)) + '")' }}
                        onClick={() => {
                          if (homeInfo.banner_url2 !== '')
                            window.location = homeInfo.banner_url2;
                        }}
                      >
                      </button>
                    </SwiperSlide>
                  )}
                </>
              )}
            </Swiper>

            <div className='pc-ptb100 flex-center communityBox moreBottom' style={{ backgroundColor: homeInfo.bg_color3 }}>
              <div className='pc1-ml40 m-w-100 commuInner'>
                <div className='home-inner1'>
                  <div className={(scrollPos > (mobile ? 735 : 1720) ? ' fade-up-ani' : '')}>
                    <a href='/notice' className='pc-font48 color11 fb'>Notice</a>
                  </div>
                  <div className='pc-mt10 flex-between morePar'>
                    <div className={(scrollPos > (mobile ? 735 : 1720) ? ' fade-up-ani ani-delay-100' : '')}>{parse(homeInfo.sub_txt4)}</div>
                  </div>
                  {mobile || tablet ? <div className="mobile"><div className="flex-end"><a href="/notice" className="">더보기</a></div></div> : ''}
                  <div className={'borderbd pc-mt40' + (scrollPos > (mobile ? 735 : 1720) ? ' fade-up-ani ani-delay-100' : '')}></div>
                </div>
                <div className='slider-part pc-mt40 homeNotice programParBox'>
                  {noticeList.map((data, index) => (
                    <NoticeItem
                      key={index}
                      data={data}
                      mobile={mobile}
                      home={true}
                      scrollPos={scrollPos}
                      index={index}
                      onClick={() => window.location = '/noticeDetail?id=' + data.id}
                    />
                  ))}
                </div>
              </div>
              <div className='m-w-100 commuInner'>
                <div className='home-inner1'>
                  <div className={(scrollPos > (mobile ? 735 : 1720) ? ' fade-up-ani' : '')}>
                    <a href='/review' className='pc-font48 color11 fb'>Review</a>
                  </div>
                  <div className='pc-mt10 flex-between morePar'>
                    <div className={(scrollPos > (mobile ? 735 : 1720) ? ' fade-up-ani ani-delay-100' : '')}>{parse(homeInfo.sub_txt3)}</div>
                    {((mobile && list3.length > 1) || list3.length > 3) && (
                      <div className='pc flex-row-center'>
                        <a href="/review" className="">더보기</a>
                        <button
                          className='h32'
                          onClick={() => sliderReviewRef.current && sliderReviewRef.current.slidePrev()}
                        >
                          <div className='left-arrow'>
                            <img src={images.img5} className='arrow' alt='' />
                          </div>
                        </button>
                        <button
                          className='h32'
                          onClick={() => sliderReviewRef.current && sliderReviewRef.current.slideNext()}>
                          <div className='right-arrow'>
                            <img src={images.img6} className='arrow' alt='' />
                          </div>
                        </button>
                      </div>
                    )}
                  </div>
                  {mobile || tablet ? <div className="mobile"><div className="flex-end"><a href="/review" className="">더보기</a></div></div> : ''}
                  <div className={'borderbd pc-mt40' + (scrollPos > (mobile ? 735 : 1720) ? ' fade-up-ani ani-delay-100' : '')}></div>
                </div>
                <div className='slider-part pc-mt40 reviewBox'>
                  <button className='h32 mobile' onClick={() => sliderReviewRef.current && sliderReviewRef.current.slidePrev()}>
                    <img src={images.img110} className='w20 invertBtn' alt='' />
                  </button>
                  {list3.length > 0 && (
                    <Swiper
                      {...sliderSettings}
                      onSwiper={(swiper) => (sliderReviewRef.current = swiper)}  // Swiper ref 설정
                    >
                      {list3.map((data, index) => (
                        <SwiperSlide key={index}>
                          <button
                            onClick={() => window.location = '/reviewDetail?id=' + data.id}
                            className={'card8 pc1-w400' + (scrollPos > (mobile ? 735 : 1720) ? ' fade-up-ani ani-delay-' + (100 * (index + 2)) : '')}
                          >
                            <div className='pc-flex-between'>
                              <div className='flex-row-center flex1'>
                                <div className='avatar' style={{ background: 'url("' + (data.profile !== '' ? Config.IMG_URL + data.profile : images.img57) + '")' }}></div>
                                <div className='pc-ml10 reviewNickPar'>
                                  <p className='pc1-font14 color11 fb reviewNick'>{data.nick_name}</p>
                                  <p className='mt4 pc-font12 color9'>{formatDate2(data.reg_date)}</p>
                                </div>
                              </div>
                              <div className='reviewTR'>
                                <div className='pc-flex-end m-mt10'>
                                  <img src={data.mark > 0 ? images.img8 : images.img7} className='pc-w24' alt='' />
                                  <img src={data.mark > 1 ? images.img8 : images.img7} className='pc-w24' alt='' />
                                  <img src={data.mark > 2 ? images.img8 : images.img7} className='pc-w24' alt='' />
                                  <img src={data.mark > 3 ? images.img8 : images.img7} className='pc-w24' alt='' />
                                  <img src={data.mark > 4 ? images.img8 : images.img7} className='pc-w24' alt='' />
                                </div>
                                <p className='pc-mt10 fb pc-text-right m-font14 color11'>{data.p_name}</p>
                              </div>
                            </div>
                            <p className={'pc-mt20 color6 pc-font14 pc-h124 txt-line5 wspl pc-lh24 reviewContent'}>{data.content}</p>
                          </button>
                        </SwiperSlide>
                      ))}
                    </Swiper>
                  )}
                  <button className='h32 mobile' onClick={() => sliderReviewRef.current && sliderReviewRef.current.slideNext()}>
                    <img src={images.img111} className='w20 invertBtn' alt='' />
                  </button>
                </div>
              </div>
            </div>
            <div className='pc-ptb100 bgf7 flex-center calendarArea moreBottom'>
              <div className='m-w-100'>
                <div className='home-inner1'>
                  <p className='pc-font48 color11 fb'>Calendar</p>
                  <div   className='pc-mt10 color9'>에세네 프라임의 전체 스케줄과 함께해요<div className="mobile">&nbsp;</div><div>&nbsp;</div></div>
                  <div className='borderbd pc-mt40'></div>
                </div>
                <div className='pc1-mt40 minh4 w-100 calendarBox'>
                  <div className='flex-center'>
                    <button className='flex-center' onClick={() => prevMonth()}>
                      <img src={images.img44} className='pc-w48' alt='' />
                    </button>
                    <DatePicker
                      ref={datePickerRef}
                      selected={selMonth}
                      dateFormat='yyyy년 M월'
                      onChange={(date) => {
                        setSelMonth(date);
                        initCalendar(date.getFullYear(), date.getMonth());
                        getCalendarList(date.getFullYear() + '-' + ('' + (date.getMonth() + 1)).padStart(2, '0'));
                        getScheduleList(date.getFullYear() + '-' + ('' + (date.getMonth() + 1)).padStart(2, '0'));
                      }}
                      customInput={<CustomInput />}
                      showMonthYearPicker
                      locale='ko'
                    />
                    <button className='flex-center' onClick={() => nextMonth()}>
                      <img src={images.img45} className='pc-w48 pc-ml100' alt='' />
                    </button>
                  </div>
                  <div className='pc-mt20 borderd w-100 bgf relative'>
                    <div className='flex-row'>
                      {['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'].map((data, index) => (
                        <p key={index} className={'pc1-font16 pc-ptb10 flex1 fb text-center color6' + (index === 0 ? ' red' : '') + (index === 6 ? ' color10' : '')}>{data}</p>
                      ))}
                    </div>
                    <div className='line1'></div>
                    <div className='flex-wrap'>
                      {dayList.map((data, index) => {
                        const dataArr = getDataArr(data);
          
                        return (
                          <div
                            key={index}
                            className={
                              'day-part' +
                              (data === 0 ? ' disable' : '') +
                              (index > dayList.length - 8 ? '' : ' borderbd') +
                              (dataArr.length > 0 ? ' active' : '')
                            }
                          >
                            <p className={'day' + (isToday(data) ? ' today' : '')}>
                              {data > 0 && data}
                            </p>
                            {dataArr.length > 0 && (
                              <>
                                <div className='pc'>
                                  {/* 먼저 ddd 클래스가 필요한 요소들을 렌더링 */}
                                  {dataArr
                                    .filter(s_data => s_data.type !== 1 && s_data.always !== "Y") // type이 1이 아니고 always가 "Y"가 아닌 요소 필터링
                                    .map((s_data, s_index) => (
                                      s_index < 3 && (
                                        <a
                                          key={s_index}
                                          className={`mt1 pd4 pc-font12 fb br4 txt-line1 ddd ${s_data.date_type === 0 ? 'startCal' :
                                            s_data.date_type === 1 ? 'middleCal' :
                                              s_data.date_type === 2 ? 'endCal' :
                                                s_data.date_type === 3 ? 'normalCal' : ''
                                            }`}
                                          style={{
                                            background: s_data.color || 'transparent',
                                            color: s_data.font_color || '#000000'
                                          }}
                                        >
                                          {s_data.date_type == 0 || s_data.date_type == 3 ? s_data.title : '　'}
                                        </a>
                                      )
                                    ))}

                                  {/* 나머지 요소들 렌더링 (type이 1인 요소들) */}
                                  {dataArr
                                    .filter(s_data => s_data.type === 1 && s_data.always !== "Y") // type이 1이고 always가 "Y"가 아닌 요소 필터링
                                    .map((s_data, s_index) => (
                                      s_index < 3 && (
                                        <a
                                          href={'/programDetail?id=' + s_data.id}
                                          key={s_index}
                                          className='mt1 pd4 colorf pc-font12 fb br4 txt-line1 bg12 programCal'
                                        >
                                          {s_data.title}
                                        </a>
                                      )
                                    ))}
                                </div>

                                <div className='mobile'>
                                  {getDataArrMo(data).length > 1 ? (
                                    <button
                                      className='bg11 pd4 pc-font12 color11 fb br4 text-center w-100 calendar-popup-btn'
                                      onClick={(e) => {
                                        setCalendarPopupList(getDataArr(data));
                                        setCalendarPopup(true);
                                        setSelPos({ x: index % 7 < 4 ? ((index % 7) + 1) * window.innerWidth / 7 : -(7 - (index % 7)) * window.innerWidth / 7, y: Math.floor(index / 7) * 60 + 40 });
                                      }}
                                    >
                                      {(getDataArr(data).length > 3 ? '3' : getDataArr(data).length) + '건'}
                                    </button>
                                  ) : (
                                    getDataArr(data)[0].type === 1 ? (
                                      <a href={'/programDetail?id=' + getDataArr(data)[0].id} className='bg11 pd4 pc-font12 color11 fb br4 flex-center mobileCalenderIcon'>1건</a>
                                    ) : (
                                      <a href={'/noticeDetail?id=' + getDataArr(data)[0].id} className='bg11 pd4 pc-font12 color11 fb br4 text-center mobileCalenderIcon'>1건</a>
                                    )
                                  )}
                                </div>
                              </>
                            )}
                          </div>
                        );
                      })}
                    </div>

                    {calendarPopup && (
                      <div className='calendar-popup' style={selPos.x > 0 ? { top: selPos.y, left: selPos.x } : { top: selPos.y, right: Math.abs(selPos.x) }}>
                        {calendarPopupList.map((s_data, s_index) => (
                          s_index < 3 && (
                            s_data.type === 1 ? (
                              <a href={'/programDetail?id=' + s_data.id} key={s_index} className={'color11 font12 txt-line1 popup-link' + (s_index > 0 ? ' mt10' : '')}>{s_data.title}</a>
                            ) : (
                              <a href={'/noticeDetail?id=' + s_data.id} key={s_index} className={'color11 font12 txt-line1 popup-link' + (s_index > 0 ? ' mt10' : '')}>{s_data.title}</a>
                            )
                          )
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className='pc-ptb40 bg16 flex-center twoArea'>
              <div className='home-inner2'>
                <div className='flex-center flex1 firstTwoArea'>
                  <div className={mobile ? 'flex-column-center' : 'flex-column'}>
                    <p className='pc-font32 color11 fb'>FAQ</p>
                    <p className='mt4 pc-font20 color11'>자주하는 질문</p>
                    <button className='mt20 btn17 m-h40' onClick={() => window.location = '/faq'}>확인하기</button>
                  </div>
                </div>
                <div className='pc v-line3'></div>
                <div className='mt20 mobile line6'></div>
                <div className='flex-center flex1'>
                  <div className={mobile ? 'mt20 flex-column-center' : 'flex-column'}>
                    <p className='pc-font32 color11 fb'>1:1 문의</p>
                    <p className='mt4 pc-font20 color11'>무엇을 도와드릴까요?</p>
                    <button className='mt20 btn17 m-h40' onClick={() => window.location = login === 0 ? '/login' : '/inquiryAdd'}>문의하기</button>
                  </div>
                </div>
              </div>
            </div>
            <div className='pc pc-ptb20 flex-center'>
              <div className='home-inner1 flex-end'>
                <button className='h64' onClick={() => {
                  const containerEl = document.querySelector('.container1');
                  if (containerEl) {
                    containerEl.scrollTo({ top: 0, behavior: 'smooth' });
                  }
                }}>
                  <img src={images.img101} className='w64' alt='' />
                </button>
              </div>
            </div>
            <Footer home={1} />
            <MFooter />
            <Menu visible={menu} onClose={() => setMenu(false)} />
          </div>
        </>
      )
      }
    </>
  )
}

export default Home